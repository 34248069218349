.subasidecontent {
    height: 100%;
    width: 220px;
    min-width: 100%;
    align-items: center;
    background-color: white;
}

.listItemSubAside {
    color: #273B4A !important;
}

.listItemSubAside.Mui-selected {
    background-color: #ECF4FD !important;
    color: #3879F6 !important;
    border-radius: 10px !important;
}

.listItemSubAside:hover {
    /* border: solid 1px #3879F6 !important; */
    border-radius: 10px !important;
}

.subAsideTitle {
    align-self: flex-start;
    margin-left: 20px !important;
    padding-top: calc(12vh - 98px);
    padding-bottom: 10px;
}