.mainBox {
    /* display: flex; */
    width: 100%;
    min-height: 100vh;
    align-items: center;
    justify-content: center;
    background-image: url('lines.svg'), linear-gradient(180deg, #1561F4 0%, #041F55 100%);
    background-repeat: no-repeat;
    background-position: bottom left;
    transition: 1s;
}

.loginHeader{
    height: 100px;
    width: 100%;

}

.loginHeaderButton{
    height: 20px;
    width: 20px;
    position: absolute;
    left: 94.44%;
    right: 3.46%;
    top: 3.42%;
    bottom: 93.64%;
}

.LifeBuoyClick{
    position: absolute;
    left: 79.58%;
    right: 2.36%;
    top: 9.08%;
    bottom: 64.26%;
}

.lifeBuoyStyle{
    background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
}

.centerBox {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.herbBox {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 69px;
    height: 69px;
    border-radius: 50%;
    background: #ffffff;
}

.herbBox img {
    max-width: 32px;
    max-height: 50px;
}

.failLogin {
    display: flex;
    flex-direction: row;
}


.forgetBox {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: #FFFFFF !important;

}

.logoBox {
    margin-top: 50px;
}

.backButton {
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    align-items: left;
}

.link {
    color: #FFFFFF !important;
    padding-left: 5px !important;
    cursor: pointer;
}


input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}