
.daneProfilu {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    margin-bottom: 10px;

}

.calyProfil {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.tytulKarty {
    display: 'flex';
    flex-direction: row;
    justify-content: space-between;
}

.link-mail {
    text-decoration: none !important;
    color: #6A6F7B;
}