.modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 500px;
    background: white;
    padding: 40px;
    border-radius: 5px;
}


.modalZatrudnienie {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 600px;
    background: white;
    padding: 0px;
    border-radius: 5px;
}