.strukturaAccordion {
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
  /*border-left: 1px solid #333;*/
}


/* .strukturaAccordionSummary:before {
  content: "";
  position: absolute;
  left: 5px;
  bottom: 4px;
  width: 1px;
  height: 80%;
  border-left: 2px solid #D6DDEF;
} */


.strukturaCard {
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
  position: relative;
}

.strukturaCard:before {
  content: "";
  position: absolute;
  left: 5px;
  top: 7px;
  width: 1px;
  height: calc(100% - 14px);
  border-left: 2px solid #D6DDEF;
}

.strukturaAccordionSummary:before {
  content: "";
  position: absolute;
  left: -5px;
  bottom: 4px;
  width: 1px;
  height: calc(100% - 8px);
  border-left: 2px solid #D6DDEF;
}

.strukturaAccordionSummary .MuiSvgIcon-root {
  color: #D6DDEF;
}