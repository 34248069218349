.pulpitIkona {
    width: 25px;
    height: 25px;
    color: "#273B4A";
    fill: "#273B4A"
}

.pulpitIconSmall {
    width: 17px;
    height: 18px;
    color: "#273B4A";
    fill: "#273B4A";
}

.pulpitIconBig {
    width: 21px;
    height: 22px;
    color: "#273B4A";
    fill: "#273B4A";
}