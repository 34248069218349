.asidecontent {
  display: flex;
  flex-direction: column;
  height: calc(100% - 48px);

  /* align-items: center; */
}

.flexContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

}


.MuiListItemIcon-root {
  margin-left: -3px;
}


.Mui-selected .asideButton {
  color: #3879F6 !important;
  fill: #3879F6 !important;
}

.Mui-selected .asideButtonText {
  color: #3879F6 !important;
  fill: #3879F6 !important;
}

.Mui-selected svg path {
  color: #3879F6;
  fill: #3879F6;
}

.asideButton:hover {
  outline: solid 1px #3879F6 !important;

  border-radius: 5px !important;
}

.asideButton {
  margin-top: 1px !important;
  margin-bottom: 1px !important;
  overflow: hidden;
  background-color: black !important;

}



.structure {
  display: flex;
  flex-direction: row;
  min-height: 100%;
  width: 100%;
  background-color: #141515;
}

.aside {
  margin-left: 2px !important;
  margin-right: 2px !important;

  width: 50px;
  transition: width 0.7s;
  background-color: black;
  flex-shrink: 0;
  flex-grow: 0;
  padding-bottom: 25px;
}

.subaside {
  width: 270px;
}

.opened {
  width: 190px;
}

.bottomList {
  margin-top: auto !important;

}

.spaceFromTop {
  height: 12vh;
  min-height: 88px;
}

@media only screen and (max-height: 650px) {
  .spaceFromTop {
    height: 5vh;
    min-height: 0px;
  }

  .aside {
    padding-bottom: 15px;
  }

  .flexContainer {
    margin-top: 100px;
    height: auto;
    justify-content: center;
  }

  .bottomList {
    margin-top: 0px !important;

  }

}