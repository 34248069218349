#circle_green {
    margin: 0 20px 0 20px;
    background: #4EAF2C;
    width: 8px;
    height: 8px;
    border-radius: 50%;
}

#circle_yellow {
    margin: 0 20px 0 20px;
    background: #E8D210;
    width: 8px;
    height: 8px;
    border-radius: 50%;
}

#circle_red {
    margin: 0 20px 0 20px;
    background: #DC4444;
    width: 8px;
    height: 8px;
    border-radius: 50%;
}

#circle_black {
    margin: 0 20px 0 20px;
    background: #6A6F7B;
    width: 8px;
    height: 8px;
    border-radius: 50%;
}