.nowyWniosekContainer {
    /* margin: 50px; */
    margin-left: 15px;
    margin-right: 15px;
}

.wniosekFormInput {
    /* text-align: center; */
    /* margin-top: -8px; */
    padding-left: 0px !important;
    width: 100%;
}

.wniosekCalendarInput input {
    /* text-align: center; */
    /* margin-top: -8px; */
    height: 7px;
}

.zlozWniosekTextField {
    width: 100%;
}

/* 
@media only screen and (max-width: 1800px) {
    .nowyWniosekContainer {
        margin: 50px;
        margin-left: -150px;
        margin-right: -150px;
    }
}

@media only screen and (max-width: 1500px) {
    .nowyWniosekContainer {
        margin: 50px;
        margin-left: 10px;
        margin-right: 10px;
    }
} */