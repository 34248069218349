.app {
  display: flex;
  flex-direction: row;
  min-height: 100vh;
  width: 100%;
}

.mainContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 0;

}

.header {
  height: 70px;
  background-color: #F0F3F7;

  transition: margin-left 0.7s;
}

.footer {
  height: 50px;
  background-color: #F0F3F7;

  transition: margin-left 0.7s;
}


.openedheaderfooter {
  transition: margin-left 0.7s;
}

.main {
  flex: 1;
  /* box-sizing: content-box; */
  background: #f7f5f5;
  height: calc(100vh - 150px);
  min-width: 0;
  max-width: 100%;
  overflow: auto;
  background-color: #F0F3F7;
  overflow-wrap: break-word;
  margin-top: 0px;
  margin-bottom: 0px;
  padding-top: 30px;
}

.icon {
  padding: 10px;
  color: white;
}

.icon:hover {
  cursor: pointer;
}